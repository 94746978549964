<template>
	<div id="content">
		<shutter-panel ref="shutterPanel" />
	</div>
</template>

<script type="text/javascript">
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'Doses',
		mixins: [ShutterCongelation],
		mounted() {
			this.setUpDosesListe()
		},
	}
</script>